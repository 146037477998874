<template>
  <div
    class="peer item"
    :class="{ '-maximized': state.maximized === id }"
    @click="handleClick"
  >
    <div
      v-if="state.screenshots"
      :style="`background:url(/faces/${screenshotNumber}.jpg); background-size: cover; background-position: center;`"
      alt=""
      class="video"
      :class="{ '-mirrored': mirrored }"
    />
    <video
      class="video"
      :class="{ '-mirrored': mirrored }"
      ref="video"
      autoplay
      playsinline
      :muted="muted"
      v-else-if="stream"
      :data-fit="state.fill ? 'cover' : 'contain'"
      autoPictureInPicture
      webkit-playsinline
      x5-playsinline
      x5-video-player-type="h5"
    />
    <div v-else class="video video-placeholder -content-placeholder">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="-icon-placeholder"
      >
        <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
      </svg>
      <label>{{l.common.waiting}}</label>
    </div>
    <div
      v-if="fingerprint && false"
      class="
        video video-placeholder video-fingerprint
        -content-placeholder
        -overlay
        -info
      "
      v-show="!state.maximized"
    >
      <label
        title="Verification code"
        class="-short"
        @click.stop.prevent="doToggleShow"
        v-show="!showCode"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-shield"
        >
          <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
        </svg>
        {{ fingerprint.substr(fingerprint.length - 4, 4) }}
      </label>
      <label
        title="Verification code"
        class="-long"
        @click.stop.prevent="doToggleShow"
        v-show="showCode"
      >
        If the person you see here confirms to see the same ID, you are securely
        connected:
        <br />
        <tt>{{ fingerprint }}</tt>
      </label>
      <label v-if="name.length > 0" :title="l.chat.name" class="-short" style="right: 5rem">
        {{ name }}
      </label>
    </div>
    <div
      v-if="id === 'self'"
      class="
        video video-placeholder video-fingerprint
        -content-placeholder
        -overlay
        -info
      "
      v-show="!state.maximized"
    >
      <label :title="l.chat.name" class="-short">
        {{ l.chat.self }}
      </label>
    </div>
    <div
      v-if="state.muteVideo && id === 'self'"
      class="video video-placeholder -content-placeholder"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="-icon-placeholder"
      >
        <path
          d="M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10"
        ></path>
        <line x1="1" y1="1" x2="23" y2="23"></line>
      </svg>
      <label>{{l.common.video_off}}</label>
    </div>
    <div
      v-if="stream && id !== 'self'"
      class="
        video video-placeholder video-fingerprint
        -content-placeholder
        -overlay
        -info
      "
      v-show="!state.maximized && !state.client"
    >
        <label :title="l.ocr.snapshot" class="-short" @click.stop.prevent="doSnapshot">
          {{ l.ocr.snapshot }}
        </label>

        <span :title="l.settings.title" class="-short" @click.stop.prevent="doRemoteSetting(id)">
          {{ l.settings.title }}
        </span>
    </div>
    <div
      v-if="stream && id !== 'self'"
      class="
        video video-placeholder video-fingerprint
        -content-placeholder
        -overlay
        -logo
      "
      v-show="state.maximized && state.maximized === id && !state.client"
    >
      <label :title="l.ocr.snapshot" class="-short" @click.stop.prevent="doSnapshot">
        {{ l.ocr.snapshot }}
      </label>

      <span :title="l.settings.title" class="-short" @click.stop.prevent="doRemoteSetting(id)">
        {{ l.settings.title }}
      </span>
    </div>
    <div
      v-if="stream && showPlayButton"
      class="video video-placeholder -content-placeholder -overlay"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="-icon-placeholder"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <polygon points="10 8 16 12 10 16 10 8"></polygon>
      </svg>
      <label>{{l.common.video_on}}</label>
    </div>
  </div>
</template>

<script>
import { messages } from "@/lib/emitter"
import { trackSilentException } from "../bugs"
import { Logger } from "../lib/logger"

const log = Logger("app:app-peer")

window.screenshotNumber = 0

export default {
  name: "app-video",
  props: {
    stream: {
      type: MediaStream,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    mirrored: {
      type: Boolean,
      default: false,
    },
    fingerprint: {
      type: String,
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    }
  },
  data() {
    return {
      screenshotNumber: ++window.screenshotNumber,
      showCode: false,
      showPlayButton: false,
    }
  },
  methods: {
    doWechatPlay(video) {
      WeixinJSBridge.invoke('getNetworkType', {}, (e) => {
          this.playVideo(video)
      })
    },
    playVideo(video) {
      let startPlayPromise = video.play()
      log("play", startPlayPromise)
      if (startPlayPromise !== undefined) {
        startPlayPromise
          .then(() => {
            // Start whatever you need to do only after playback has begun.
            // messages.emit("videoPreview", video)
          })
          .catch((error) => {
            if (error.name === "NotAllowedError") {
              this.showPlayButton = true
            } else {
              console.log(error)
              trackSilentException(error)
            }
          })
      }
    },
    doRemoteSetting(id) {
      let message = "sys|requestUserDevice|" + id
      if(id == 'self') {
        return;
      }
      let name = this.nameString || "system"
      let current = new Date()
      let ampm = current.getHours() < 12 ? "AM" : "PM"
      let time =
        current.getHours() +
        ":" +
        current.getMinutes() +
        ":" +
        current.getSeconds() +
        " " +
        ampm
      let tips = ""
      let is_cmd = true

      //console.log(name, message, time, is_cmd, tips)
      this.$layer.msg('设备获取请求已发送')
      messages.emit("chatMessage", { name, message, time, is_cmd, tips })

    },
    async doConnectStream(stream) {
      log("doConnectStream", this.title, stream)
      if (stream) {
        try {
          await this.$nextTick()

          let video = this.$refs.video
          log("connectStreamToVideoElement", stream, video)
          // console.log("connectStreamToVideoElement", stream, video)
          if (stream) {
            if ("srcObject" in video) {
              video.srcObject = stream
            } else {
              video.src = window.URL.createObjectURL(stream) // for older browsers
            }

            console.log('videoCount', this.state.videoCount);
            if (this.state.videoCount > 0) {
              messages.emit("videoCreated", video)
            }
            
            this.state.videoCount++;

            // Keep in mind https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
            // But if the user allows to access camera it should be fine
            // https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide
            video.onloadedmetadata = (e) => this.playVideo(video)
            video.onloadeddata = (e) => this.playVideo(video)
            // video.oncanplay = (e) => this.playVideo(video)
            // video.oncanplaythrough  = (e) => this.playVideo(video)
            // video.ondurationchange  = (e) => this.playVideo(video)

            // document.addEventListener('touchstart', () => {
            //   this.playVideo(video)
            // }, false)

            video.onpause = (e) => this.playVideo(video)

            if (window.WeixinJSBridge) {
              this.doWechatPlay(video)
            }else {
              document.addEventListener("WeixinJSBridgeReady", () => {
                this.doWechatPlay(video)
              }, false)
            }
          }
        } catch (err) {
          trackSilentException(err)
        }
      }
    },
    handleClick() {
      if (this.showPlayButton) {
        this.doPlay()
      } else if (this.state.maximized === this.id) {
        this.state.maximized = ""
      } else {
        this.state.maximized = this.id
      }
    },
    doToggleShow(ev) {
      this.showCode = !this.showCode
    },
    async doPlay() {
      try {
        log("force play manually")
        this.$refs?.video?.play()
        this.showPlayButton = false
      } catch (err) {
        trackSilentException(err)
      }
    },
    doSnapshot(e) {
      //console.log("doSnapshot")
      let scale = 0.75
      let video = this.$refs.video
      let canvas = document.createElement("canvas")
      canvas.width = video.videoWidth * scale > 800 ? 800 : video.videoWidth * scale
      canvas.height = video.videoHeight * scale > 600 ? 600 : video.videoHeight * scale
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)

      messages.emit("ToggleCropTool", 1)
      messages.emit("newSnapshot", canvas.toDataURL("image/jpeg"))
      //messages.emit("switchMode", "ocr")

    },
    releaseAVsource(quit) {
        this.state.stream?.getTracks().forEach((track) => {
            if (typeof track.stop === 'function' && track.readyState !== 'ended') {
              track.stop()
              const trackStoppedEvt = new MediaStreamTrackEvent('ended', { track })
              track.dispatchEvent(trackStoppedEvt)
            }
        })

      if(quit) {
        setTimeout(() => {
            window.opener = null
            window.open(' ', '_self')
            window.close()
        }, 1000)
      }
    }
  },
  async mounted() {
    // webrtc.on('stream', async () => {
    //   await this.$nextTick()
    //   await this.doConnectStream(this.stream)
    // })
    if (this.stream) {
      await this.doConnectStream(this.stream)
    }

    messages.on("releaseAVsource", this.releaseAVsource)

  },
  created() {
    //this.obs = new OBSWebSocket()
    //messages.emit("videoCreated", this.stream, this)
  },
  watch: {
    stream(value) {
      this.doConnectStream(value)
      if(this.state.autoRecord && !this.state.onAir && this.state.recordType === "obs") {
        messages.emit("ToggleRecord", 1)
      }
    },
  },
}
</script>
